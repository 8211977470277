import React from 'react';
import {
  StyledServicesWrapper,
  StyledService,
  StyledServicesDivider
} from './styled';
import { StyledHeroSubtitle } from 'templates/Home/Hero/styled';
import MarkdownPreview from '@uiw/react-markdown-preview';

const Services = ({ contentSubtitle, services, pageContext }) => {
  // const { originalPath } = pageContext;
  // let pathBase = originalPath.split('/')[1];
  console.log(services);
  return (
    <>
      {services.length > 2 ? (
        <>
          {contentSubtitle && (
            <StyledHeroSubtitle>{contentSubtitle}</StyledHeroSubtitle>
          )}
          <StyledServicesWrapper>
            {services.map((service, index) => {
              const {
                title,
                alternativeSlug,
                altDescription,
                altIcon,
                altCallToAction
              } = service;

              return (
                <>
                  <StyledService>
                    <img src={altIcon?.url} alt={title} />
                    <span>{title}</span>
                    <MarkdownPreview source={altDescription} />
                    <a href={`/services/${alternativeSlug}`}>
                      {altCallToAction}
                    </a>
                  </StyledService>

                  {index === services.length - 1 ? null : (
                    <StyledServicesDivider />
                  )}
                </>
              );
            })}
          </StyledServicesWrapper>
        </>
      ) : (
        <>
          {contentSubtitle && (
            <StyledHeroSubtitle>{contentSubtitle}</StyledHeroSubtitle>
          )}
          <StyledServicesWrapper>
            {services.map((service, index) => {
              const {
                title,
                alternativeSlug,
                altDescription,
                altIcon,
                altCallToAction
              } = service;

              return (
                <>
                  <StyledService little>
                    <img src={altIcon?.url} alt={title} />
                    <div>
                      <span>{title}</span>
                      <MarkdownPreview source={altDescription} />
                      <a href={`/services/${alternativeSlug}`}>
                        {altCallToAction}
                      </a>
                    </div>
                  </StyledService>

                  {index === services.length - 1 ? null : (
                    <StyledServicesDivider />
                  )}
                </>
              );
            })}
          </StyledServicesWrapper>
        </>
      )}
    </>
  );
};

export default Services;
