import React from 'react';
import { Link } from 'gatsby';
import Button from 'components/Form/Button';
import { StyledCTAWrapper, StyledCTATitle } from './styled';

const ContactUsCTA = (props) => {
  const { title, buttonText, ...otherProps } = props;
  return (
    <StyledCTAWrapper {...otherProps}>
      {!otherProps.little && <StyledCTATitle>{title}</StyledCTATitle>}

      <Button
        style={{ justifyContent: 'center' }}
        to="#contact"
        as={Link}
        type="button"
        callToAction={true}
        withIcon={true}
      >
        {buttonText}
      </Button>
    </StyledCTAWrapper>
  );
};

export default ContactUsCTA;
