import styled from 'styled-components';
import colors from 'assets/global/colors';

export const StyledCTAWrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1rem;
  padding: 3rem;
  margin: 30px 0;
  background-color: ${(props) =>
    props.little ? colors.white : colors.gray50};
`;

export const StyledCTATitle = styled.h2`
  margin: 0;
  text-align: center;
  font-size: 32px;
  font-weight: 500;

  @media screen and (max-width: 980px) {
    font-size: 16px;
  }
`;
