import React from 'react';
import { StyledWrapper, StyledSubtitle } from 'assets/global/styled';
import {
  StyledAwardsContainer,
  StyledAwardsWrapper,
  StyledPaddingHorizontal
} from './styled';

const Awards = (props) => {
  const { awards, title, ...otherProps } = props;
  return (
    <StyledAwardsContainer {...otherProps}>
      <StyledWrapper>
        {props.title ? (
          <StyledPaddingHorizontal>
            <StyledSubtitle {...otherProps}>{title}</StyledSubtitle>
          </StyledPaddingHorizontal>
        ) : null}
        <StyledAwardsWrapper>
          {awards?.map((award, index) => {
            const awardImage = award.image;

            return (
              <img
                src={awardImage?.url}
                height="130px"
                width="auto"
                alt={awardImage?.alt}
                key={index}
              />
            );
          })}
        </StyledAwardsWrapper>
      </StyledWrapper>
    </StyledAwardsContainer>
  );
};

export default Awards;
