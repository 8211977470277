import styled from 'styled-components';
import colors from 'assets/global/colors';

export const StyledServicesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 4rem;
  width: 100%;

  @media screen and (max-width: 980px) {
    flex-direction: column;
    gap: 2rem;
  }
`;

export const StyledService = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.little ? 'row' : 'column')};
  gap: 2rem;

  flex: 1 0 calc(25% - 6rem);
  ${(props) => (props.little ? null : 'text-align: center;')}

  @media screen and (max-width: 980px) {
    text-align: center;
    flex-direction: column;
    gap: 1rem;
  }

  img {
    height: ${(props) => (props.little ? '150px' : '60px')};
    width: auto;
    ${(props) => (props.little ? null : 'align-self: center;')};

    @media screen and (max-width: 980px) {
      align-self: center;
      height: 40px;
    }
  }

  span,
  p {
    font-size: 24px;

    @media screen and (max-width: 980px) {
      font-size: 14px;
    }
  }

  span {
    margin: 0px 0px 0px 0px;
    display: block;
    font-weight: 600;
  }

  p {
    font-weight: 300;
    margin: 0;
    font-size: 18px;
  }

  a {
    display: block;
    font-size: 18px;
    font-weight: 600;
    text-decoration: none;
    margin: 0px 0px 0px 0px;

    color: ${colors.red400};

    @media screen and (max-width: 980px) {
      font-size: 14px;
    }
  }
`;

export const StyledServicesDivider = styled.span`
  border: 1px solid ${colors.gray50};
  width: 80%;
  align-self: center;

  @media screen and (min-width: 981px) {
    display: none;
  }
`;
