import React from 'react';
import {
  StyledNumbersContainer,
  StyledNumbersWrapper,
  StyledNumbersDivider,
  StyledNumbersTitle,
  StyledNumbersDescription
} from './styled';
import { StyledHeroSubtitle } from 'templates/Home/Hero/styled';

const RelevantNumbers = ({ contentSubtitle, numbers }) => {
  return (
    <>
      {numbers.length > 0 ? (
        <>
          {contentSubtitle && (
            <StyledHeroSubtitle>{contentSubtitle}</StyledHeroSubtitle>
          )}
          <StyledNumbersContainer>
            {numbers.map((n, index) => {
              const { title, description } = n;

              return (
                <React.Fragment key={index}>
                  <StyledNumbersWrapper>
                    <StyledNumbersTitle>{title}</StyledNumbersTitle>

                    <StyledNumbersDescription>
                      {description}
                    </StyledNumbersDescription>
                  </StyledNumbersWrapper>

                  {index === numbers.length - 1 ? null : (
                    <StyledNumbersDivider />
                  )}
                </React.Fragment>
              );
            })}
          </StyledNumbersContainer>
        </>
      ) : null}
    </>
  );
};

export default RelevantNumbers;
