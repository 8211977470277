import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import MarkdownPreview from '@uiw/react-markdown-preview';
import {
  StyledPackages,
  StyledPackagesSection,
  StyledPackagesContainer,
  StyledPackagesDescription,
  StyledPackagesWrapper,
  StyledPackagesWrapperSwiper,
  StyledPackageCard,
  StyledPackageCardHeader,
  StyledPackageCardBody
} from './styled';
import { StyledHeroSubtitle } from 'templates/Home/Hero/styled';

const Packages = ({ page, packages, showSeeMore }) => {
  const { content5Subtitle, content5 } = page;

  return (
    <>
      {packages.length > 0 ? (
        <StyledPackages>
          <StyledPackagesSection>
            <StyledHeroSubtitle>
              {content5Subtitle}
            </StyledHeroSubtitle>

            <StyledPackagesDescription>
              <MarkdownPreview source={content5} />
            </StyledPackagesDescription>
          </StyledPackagesSection>

          <StyledPackagesContainer>
            <StyledPackagesWrapper>
              {packages.map((p, index) => {
                const { title, subtitle, description } = p;

                return (
                  <StyledPackageCard key={index}>
                    <StyledPackageCardHeader>
                      <span>{title}</span>
                      <span>{subtitle}</span>
                    </StyledPackageCardHeader>

                    <StyledPackageCardBody>
                      <MarkdownPreview source={description} />
                    </StyledPackageCardBody>
                  </StyledPackageCard>
                );
              })}
            </StyledPackagesWrapper>

            <StyledPackagesWrapperSwiper>
              <Swiper
                slidesPerView={1}
                loop="true"
                navigation
                pagination={{ clickable: true }}
              >
                {packages.map((p, index) => {
                  const { title, subtitle, description, url } = p;

                  return (
                    <SwiperSlide key={index}>
                      <StyledPackageCard>
                        <StyledPackageCardHeader>
                          <span>{title}</span>
                          <span>{subtitle}</span>
                        </StyledPackageCardHeader>

                        <StyledPackageCardBody>
                          <MarkdownPreview source={description} />
                        </StyledPackageCardBody>
                      </StyledPackageCard>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </StyledPackagesWrapperSwiper>
          </StyledPackagesContainer>

          {showSeeMore ? (
            <div>
              <StyledPackagesSection>
                <a href="/services/ia">{showSeeMore}</a>
              </StyledPackagesSection>
            </div>
          ) : null}
        </StyledPackages>
      ) : null}
    </>
  );
};

export default Packages;
