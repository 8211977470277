import React from 'react';
import {
  StyledTechsContainer,
  StyledTechsWrapper,
  StyledTechsItem,
  StyledTechsItemWithTitle,
  StyledTechsItemTitle,
  StyledTechItemsExtraConatainer
} from './styled';
import {
  StyledHeroTitle,
  StyledHeroSubtitle
} from 'templates/Home/Hero/styled';

const Technologies = (props) => {
  const {
    contentSubtitle,
    contentTitle,
    technologies,
    ...otherProps
  } = props;

  let orderBy = ['General', 'Mobile', 'Cloud', 'IA'];

  if (otherProps.sorted) {
    return (
      <>
        {technologies.length > 0 ? (
          <>
            {contentSubtitle && (
              <StyledHeroSubtitle>
                {contentSubtitle}
              </StyledHeroSubtitle>
            )}
            <StyledTechsContainer>
              {contentTitle && (
                <StyledHeroTitle>{contentTitle}</StyledHeroTitle>
              )}

              <StyledTechsWrapper>
                <StyledTechItemsExtraConatainer>
                  {orderBy.map((order, index) => {
                    let technologies_sorted = technologies.filter(
                      (technology) =>
                        technology.technologyType[0] === order
                    );

                    return (
                      <StyledTechsItemWithTitle
                        key={index}
                        {...otherProps}
                      >
                        <StyledTechsItemTitle>
                          {order}
                        </StyledTechsItemTitle>
                        {technologies_sorted.map((t, index) => {
                          const { name, image } = t;

                          return (
                            <React.Fragment key={index}>
                              <StyledTechsItem {...otherProps}>
                                {image?.url && (
                                  <img
                                    src={image?.url}
                                    alt={image?.alt || name}
                                  />
                                )}

                                <span>{name}</span>
                              </StyledTechsItem>
                            </React.Fragment>
                          );
                        })}
                      </StyledTechsItemWithTitle>
                    );
                  })}
                </StyledTechItemsExtraConatainer>
              </StyledTechsWrapper>
            </StyledTechsContainer>
          </>
        ) : null}
      </>
    );
  } else {
    return (
      <>
        {technologies.length > 0 ? (
          <>
            {contentSubtitle && (
              <StyledHeroSubtitle>
                {contentSubtitle}
              </StyledHeroSubtitle>
            )}
            <StyledTechsContainer>
              {contentTitle && (
                <StyledHeroTitle>{contentTitle}</StyledHeroTitle>
              )}

              <StyledTechsWrapper>
                {technologies.map((t, index) => {
                  const { name, image } = t;

                  return (
                    <React.Fragment key={index}>
                      <StyledTechsItem {...otherProps}>
                        {image?.url && (
                          <img
                            src={image?.url}
                            alt={image?.alt || name}
                          />
                        )}

                        <span>{name}</span>
                      </StyledTechsItem>
                    </React.Fragment>
                  );
                })}
              </StyledTechsWrapper>
            </StyledTechsContainer>
          </>
        ) : null}
      </>
    );
  }
};

export default Technologies;
