import styled from 'styled-components';
import colors from 'assets/global/colors';

export const StyledPackages = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;
  width: 100%;
  max-width: 100%;

  @media screen and (max-width: 980px) {
    flex-direction: column;
  }
`;

export const StyledPackagesSection = styled.div`
  /* width: 20rem; */
  width: 100%;

  /* @media screen and (max-width: 980px) {
    width: 100%;
  } */

  a {
    display: block;
    font-size: 18px;
    font-weight: 600;
    text-decoration: none;
    margin: 0px 0px 0px 0px;

    color: ${colors.red400};

    @media screen and (max-width: 980px) {
      font-size: 14px;
    }
  }
`;

export const StyledPackagesContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 3rem;
  width: 100%;
  margin-top: 20px;

  @media screen and (max-width: 980px) {
    flex-direction: column;
    margin-top: 0px;
  }
`;

export const StyledPackagesDescription = styled.div`
  font-weight: lighter;
  color: ${colors.gray300};
  margin: 0;

  @media screen and (max-width: 980px) {
    max-width: 100%;
  }
`;

export const StyledPackagesWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  column-gap: 2rem;

  @media screen and (max-width: 980px) {
    display: none;
  }
`;

export const StyledPackagesWrapperSwiper = styled.div`
  flex-grow: 1;
  display: none;

  @media screen and (max-width: 980px) {
    display: block;
  }
`;

export const StyledPackageCard = styled.a`
  flex-grow: 1;
  border: 1px solid ${colors.gray50};
  border-radius: 0.5rem;
  max-width: 60%;
  margin: 0 auto;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1),
    0 1px 2px -1px rgb(0 0 0 / 0.1);

  @media screen and (max-width: 980px) {
    margin-bottom: 3rem;
  }
`;

export const StyledPackageCardHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid ${colors.gray50};

  span {
    font-size: 24px;

    @media screen and (max-width: 980px) {
      font-size: 18px;
    }

    &:first-child {
      font-weight: bold;
    }

    &:last-child {
      color: ${colors.gray700};
      font-weight: lighter;
      font-size: 20px;
    }
  }
`;

export const StyledPackageCardBody = styled.div`
  padding: 1rem;
  color: ${colors.gray300};
  font-size: 18px;
  margin: 0;

  @media screen and (max-width: 980px) {
    font-size: 14px;
  }
`;
