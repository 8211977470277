import styled from 'styled-components';
import colors from 'assets/global/colors';

export const StyledTechsContainer = styled.section`
  display: flex;
  flex-direction: row;
  column-gap: 4rem;

  @media screen and (max-width: 980px) {
    flex-direction: column;
  }
`;

export const StyledTechsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  height: fit-content;
`;

export const StyledTechsItem = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 0.5rem;
  background-color: ${(props) =>
    props.sorted ? 'none' : colors.gray50};
  padding: 0.5rem;
  border-radius: 0.6rem;
  align-items: center;
  height: fit-content;

  span {
    font-size: 14px;
  }

  img {
    width: auto;
    height: 30px;

    @media screen and (max-width: 980px) {
      height: 20px;
    }
  }
`;

export const StyledTechsItemWithTitle = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 800px;

  @media screen and (max-width: 980px) {
    width: 100%;
  }
`;

export const StyledTechsItemTitle = styled.div`
  padding: 15px 0px;
  width: 100%;

  @media screen and (max-width: 980px) {
    font-size: 12px;
  }
`;

export const StyledTechItemsExtraConatainer = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 980px) {
    flex-direction: column-reverse;
  }
`;
