import styled from 'styled-components';
import colors from 'assets/global/colors';
import fonts from 'assets/global/fonts';

export const StyledNumbersContainer = styled.section`
  display: flex;
  justify-content: space-between;
  width: 100%;
  row-gap: 2rem;

  @media screen and (max-width: 980px) {
    flex-direction: column;
  }
`;

export const StyledNumbersWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledNumbersDivider = styled.span`
  border: 1px solid ${colors.red400};
  margin: 0 3rem;

  @media screen and (max-width: 980px) {
    display: none;
  }
`;

export const StyledNumbersTitle = styled.span`
  margin: 0;
  font-family: ${fonts.primary};
  color: ${colors.gray300};
  line-height: 1.1;
  font-size: 32px;
  font-weight: 600;
`;

export const StyledNumbersDescription = styled.span`
  margin: 0;
  color: ${colors.gray600};

  font-weight: 400;
  font-size: 21px;
`;
